import React from 'react';


const Slide2 = () => {

  const btnClick = (value) => {

    const url = "https://swiss.ramenpotential.com/alex/c";

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ response: value })
    })
  }

  return (
    <div className="section slide-page">
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-three-quarters content has-text-left">
            <p>I would like to talk to you about things that interest you and get to know you better but without having to find a pretext of a tweet, it limits the conversation and puts too much pressure. I think you will be a good sounding board, a great partner. All I ask for is if we could start off being friends? </p>
            <p>So far I have assumed that you are not seeing anyone right now! I maybe wrong. Or maybe you are not even looking for something like this, I completely understand if so. There are plenty of things that are not ideal - location, you probably don’t know anything about me etc. Despite all that, if it's a ‘yay’, please drop me a message on Twitter, maybe your phone number. If its a ’nay’,  I would appreciate a direct ’No’.</p>
            <p>The rejection would be painful but the possibility of getting to know and maybe being with you is totally worth the hurt of hearing no.</p>
            <div>
              <button className="button"
                onClick={() => btnClick('Yes')}
                style={{
                  height: '7rem',
                  width: '95%',
                  background: '#09b310', 
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '2rem',
                }}>
                Yay! <span role="img" aria-label="bliss">😇</span>
              </button>
              <button
                className="button"
                onClick={() => btnClick('No')}
                style={{
                  height: '1rem',
                  width: '3%',
                  background: 'red',
                  fontSize: '0.4rem',
                  color: 'white',
                  marginLeft: '2%'
              }}>No. <span role="img" aria-label="punch">👊</span></button>
            </div>
            <p style={{ marginTop: '1rem' }}> <b>CTA</b> - If you are single and interested, can we talk about everything under the stars? Can I <i>raise</i> your phone number? If no, please be direct.</p>
            <p><i>Note - The buttons don't actually work, they are merely a representation of my hope! Please DM me on Twitter.</i></p>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Slide2;
