import React from 'react';


const Slide2 = () => {
  return (
    <div className="section slide-page" >
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-three-quarters" style={{ marginTop: '0px' }}>
            <p>I hope, someday . . .</p>
            <div className="tennis-div" style={{ margin: '0.5rem 0px' }}></div>
            <h1 className="title is-4" style={{ color: 'white' }}>Thanks!</h1>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Slide2;
