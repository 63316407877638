import React from 'react';

const Slide6 = () => {
  return (
    <div className="slide-page">
      <div className="section dogs-page">
        <div className="container">
        </div>
      </div>
    </div>
  )
}

export default Slide6;
