import React from 'react';

const Slide2 = () => {
  return (
    <div className="section slide-page">
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-three-quarters content has-text-left">
            <p>
              I have been bugging you in Twitter DM for a while, just been finding context to message you but I am not too comfortable doing it anymore. Honestly I feel a bit like a fraud, I never thought that I will approach someone like this over social media, virtually. I have always had the notion that love is something that you are not supposed to go out and seek but it just happens, <i>maybe bollywood influence</i>. But with you, I just can't let it go. I can't be indifferent and leave it to the destiny. This is one of the most important games, and I can't afford to not give it a good shot.
            </p>

            <p>
              Anyway, I like that you have varied interests, are full on desi, like coffee and nature, have a fun side, like Sunset series, care about environment and animals, like books, colors, music and arts, are into startups and ideas and are clearly a high achiever but more importantly you seem to be soft, kind, full of life, original, graceful like a queen, caring and apparantly can make perfectly round aloo parathas! For some reason, I really like the vibe that you bring, I like you!
            </p>

            <p>
              I have been thinking about you for a while now, like the one you think about right before you sleep, even in middle of an engrossing thriller movie, the one whose messages you anxiously await, where you double check each of your messages to make sure that there are no typos or grammatical errors or sit on a fine sunday to just go through their Twitter timeline to know them better (Well, stalking! :P). Like you look at a puppy and the only thought you have is, 'oh! she would have definitely played with it!’.
            </p>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Slide2;
