import React from 'react';


const Slide2 = () => {
  return (
    <div className="section slide-page" >
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-three-quarters" style={{ marginTop: '0px' }}>
            <p>Maybe by the end of this slideshow, I will be left embarrassed but I can't leave you spotless either!</p>
            <div className="twitter-div" style={{ margin: '0.5rem 0px'}}></div>
            <p>BTW, did Justin Bieber follow you? <span role="img" aria-label="laugh">😂</span></p>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Slide2;
