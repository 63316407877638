import React from 'react';

const Slide4 = () => {
  return (
    <div className="slide-page">
      <div className="section favs-page">
        <div className="container">
        </div>
      </div>
    </div>
  )
}

export default Slide4;
