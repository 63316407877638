import React from 'react';

const Slide2 = () => {
  return (
    <div className="section slide-page">
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-three-quarters">
            <h1 className="title is-3" style={{color: "white"}}>One more thing . . .</h1>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Slide2;
