import React from 'react';

const Slide1 = () => {
  return (
    <div className="section slide-page">
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-three-quarters content has-text-left">
            <p>Hi Sanya, I know you like or atleast are in to the VC world! So I am putting up a show of geekry and slides!</p>
            <p>Maybe it's a little over the top, or disappointing or like one of the ridiculous mischiefs of Calvin, but I hope it's at least remarkable.</p>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Slide1;
