import React, { Component } from 'react';

import 'bulma/css/bulma.css';
import './App.css';
import Slide1 from './slides/slide1';
import Slide2 from './slides/slide2';
import Slide3 from './slides/slide3';
import Slide4 from './slides/slide4';
import Slide5 from './slides/slide5';
import Slide6 from './slides/slide6';
import Slide7 from './slides/slide7';
import Slide8 from './slides/slide8';
import Slide9 from './slides/slide9';
import Slide10 from './slides/slide10';
import Slide11 from './slides/slide11';

import Login from './slides/Login';

const url = "https://swiss.ramenpotential.com/alex/c"

class App extends Component {

  state = {
    sn: 0,
  }

  componentDidMount() {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ slide: 'homepage landing' })
    })
  }

  handleSlideChange = (counter) => { // 1 || -1
    if((this.state.sn === 11) && (counter === 1)) {
      return;
    }

    if ((this.state.sn === 1) && (counter === -1)) {
      return;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ slide: this.state.sn, direction: (counter === 1 ? 'ahead' : 'back') })
    })

    this.setState({ sn: this.state.sn + counter });
  }

  checkPassword = (password) => {
    const loginUrl = "https://swiss.ramenpotential.com/alex/login";

    fetch(loginUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password })
    }).then((res) => {
      return res.json();
    }).then((data) => {
      console.log(data);
      if (data.success === true) {
        this.setState({ sn: 1 }, () => {
          var sliderBody = document.getElementById('slider-body');
          console.log(sliderBody)
          if (sliderBody) {
            document.body.addEventListener('keydown', (e) => {
              console.log('eee', e)
              this.detectKeyDown(e.key || e.keyCode);
            });
          }
        })
      } else {
        alert(data.message);
      }
    })
  }

  detectKeyDown = (val) => {
    if(val === 'ArrowLeft' || val === 37) {
      return this.handleSlideChange(-1);
    }

    if (val === 'ArrowRight' || val === 39) {
      return this.handleSlideChange(1);
    }
  }

  render () {
    return (
      <div className="App">
        <div>
          {
            this.state.sn === 0 ?
              <Login checkPassword={this.checkPassword} />
            :
            <div id="slider-body" onKeyPress={this.detectKeyDown}>
              { (slider(this.state.sn)) }
            </div>
          }
        </div>

        {
          this.state.sn === 0 ?
            null
            :
            <div className="slide-switch">
              {this.state.sn !== 1 ? <div className="button minus" onClick={() => this.handleSlideChange(-1)}>➜</div> : null}
              {this.state.sn !== 11 ? <div className="button plus" onClick={() => this.handleSlideChange(1)}>➜</div> : null}
            </div>
        }

        {
          this.state.sn !== 0 ?
            <iframe title="khumaar" width="0" height="0" style={{display: 'none'}} src="https://www.youtube.com/embed/_bw9oKMpUQQ?start=22&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" autoPlay></iframe>
            :
            null
        }
      </div>
    );
  }
}

const slider = (sn) => {
  switch(sn) {
    case 1:
      return <Slide1 />;
    case 2:
      return <Slide2 />;
    case 3:
      return <Slide3 />;
    case 4:
      return <Slide4 />;
    case 5:
      return <Slide5 />
    case 6:
      return <Slide6 />;
    case 7:
      return <Slide7 />;
    case 8:
      return <Slide8 />;
    case 9:
      return <Slide9 />;
    case 10:
      return <Slide10 />;
    case 11:
      return <Slide11 />;
    default:
      return <Slide1 />;
  }
}

export default App;
