import React from 'react';

class Login extends React.Component {

  state = {
    password: ''
  }

  // lets make a ajax call and then update the parent state
  
  render () {
    return (
      <div className="section login-page" style={{ height: '100vh' }}>
        <div className="container">
          <div className="columns">
            <div className="column"></div>
            <div className="column is-vcentered login">
              <h1 className="title is-2" style={{ color: 'white' }}>The Pitch</h1>
              <div className="field">
                <div className="control">
                  <input className="input"
                    type="password"
                    placeholder="Please enter the password"
                    onChange={(e) => this.setState({ password: e.target.value })}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control has-text-centered">
                  <button className="button is-link" onClick={() => this.props.checkPassword(this.state.password)}>Submit</button>
                </div>
              </div>
            </div>
            <div className="column"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;
