import React from 'react';

const Slide2 = () => {
  return (
    <div className="section slide-page">
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-three-quarters content has-text-left">
            <p>I am Prashant. I like to read, write, code, play sports, learn, teach. I also like coffee, movies, nature, good conversations, and bunch of other things.
            Also, some day I might feature in the <i>'Forbes 30 under 30 dissapointment list'</i> for doing pretty much anything in the kitchen, well maybe other than making coffee.
            Generally optimistic, I am an okay person with some good qualities and plenty of flaws.</p>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Slide2;
