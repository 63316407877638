import React from 'react';


const Slide2 = () => {
  return (
    <div className="section slide-page">
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column is-three-quarters content has-text-left">
            <p>If nothing, I hope I have given you a fun story to tell people. <i>Oh! how once this guy put up a slideshow . . .</i>!</p>
            <p>I hope you feel a bit more wanted and appreciated.</p>
            <p>No matter what your response, you are amazing and like once I said in DMs, you are authentic and you will be a star!</p>
            <p></p>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Slide2;
